<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{
              name: 'OrganizationHome',
            }">
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              @click="goBackOrList"
            >{{ "API Token 管理列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
            >{{
                isReadOnly ? "查看API Token" : isEditing ? "編輯API Token" : "新增API Token"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看API Token" : isEditing ? "編輯API Token" : "新增API Token"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="8"
                  label="*通路"
                >
                  <b-form-select
                    :state="v$.token.merchant_id.$error ? false : null"
                    v-model="token.merchant_id"
                    :disabled="isReadOnly"
                    :options="merchants"
                    class="mr-3"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!v$.token.merchant_id.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="8"
                  label="*類型"
                >
                  <b-form-select
                    :state="v$.token.type.$error ? false : null"
                    v-model="token.type"
                    :disabled="isReadOnly"
                    :options="types"
                    class="mr-3"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!v$.token.type.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="8"
                  label="備註"
                >
                  <b-textarea
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    rows="4"
                    v-model="token.memo"
                    :readonly="isReadOnly"
                  ></b-textarea>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label-size="m"
                  content-cols="12"
                  content-cols-lg="8"
                  label="過期時間"
                  >
                  <datepicker
                    :language="zh"
                    :format="dateFormatter"
                    bootstrap-styling
                    v-model="token.expired_at"
                    :disabled="isReadOnly"
                    @input="formatDate"
                  ></datepicker>
                </b-form-group>

                <div v-if="isReadOnly || isEditing">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="m"
                    content-cols="12"
                    content-cols-lg="8"
                    label="App ID"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      v-model="token.app_id"
                      :readonly="isReadOnly || isEditing"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="m"
                    content-cols="12"
                    content-cols-lg="8"
                    label="App Secret"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      v-model="token.app_secret"
                      :readonly="isReadOnly || isEditing"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="m"
                    content-cols="12"
                    content-cols-lg="8"
                    label="Token"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      v-model="token.token"
                      :readonly="isReadOnly || isEditing"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="m"
                    content-cols="12"
                    content-cols-lg="8"
                    label="最後存取時間"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="text"
                      v-model="token.last_access"
                      :readonly="isReadOnly || isEditing"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="goBackOrList"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
              >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import {mapGetters, mapState} from "vuex"
import useVuelidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import tokenApi from "@/apis/token";
import {zh} from "vuejs-datepicker/dist/locale"
import merchantApi from "@/apis/merchant";
import {format} from "date-fns";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Datepicker },
  validations() {
    return {
      token: {
        merchant_id: {required},
        type: {required},
        memo: {},
        expired_at: {}
      }
    };
  },

  data() {
    return {
      zh,
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      merchants: [],
      types: [
        { value: null, text: "請選擇" },
        { value: "open_api", text: "Open API" },
        { value: "saw_api", text: "SAW" },
      ],
      token: {
        merchant_id: null,
        type: null,
        app_id: null,
        app_secret: null,
        token: null,
        memo: null,
        expired_at: null,
        last_access: null,
      },
      showLoadingUpload: false,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    ...mapGetters('route', ['from']),

    isReadOnly() {
      return this.$route.name === "TokenView";
    },

    dateFormatter() {
      return "yyyy-MM-dd";
    },
  },
  mounted() {
    this.fetchMerchants();
    this.token.merchant_id = this.$route.query.merchant_id;
    if (!this.$route.params.tokenId) {
      this.isEditing = false;
    } else {
      this.fetchToken();
      this.isEditing = true;
    }
  },
  methods: {
    async fetchToken() {
      this.showLoading = true;
      const response = await tokenApi.getToken(
        this.$route.params.tokenId
      );
      this.token = response.data.data;
      this.showLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        this.formatDate()
        if (this.isEditing) {
          let response = await tokenApi.updateToken(this.$route.params.tokenId, this.token);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

          }
        } else {
          let response = await tokenApi.createToken(this.organization, this.token);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });
          }
        }
        this.goBackOrList()
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];
    },
    formatDate() {
      if (this.token.expired_at === null) return;
      this.token.expired_at = format(new Date(this.token.expired_at), "yyyy-MM-dd");
    },
    goBackOrList() {
      if (this.from) {
        // with query
        this.$router.push(this.from.fullPath);
      } else {
        this.$router.push({ name: 'TokenList' });
      }
    }
  },
};
</script>
<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
